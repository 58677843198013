import axios from "axios";
import { Toast } from "vant";
console.log(process.env.VUE_APP_BASE_API);
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: process.env.VUE_APP_BASE_API+'/refactor/jml', // url = base url + request url
  // baseUrl: "/api",
  withCredentials: true,
  crossDomain: true,
  // headers: {
  //   // "Content-Type": "charset=UTF-8;application/x-www-form-urlencoded"
  //   // Cookie: "SESSION=" + "143c9329-7c77-4480-bf85-9459737918bc",
  // },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    // return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 100, it is judged as an error.
    console.log(res);
    if (res.code != 200) {
      Toast({
        message: res.msg,
        forbidClick: true,
      });
      return Promise.reject(res);
      // return Promise.reject(new Error(res.msg || "Error"));
    } else if (res.code == 900) { 
      // var  projectCode = sessionStorage.getItem("projectCode");
      // var qrCode =sessionStorage.getItem("qrCode", this.qrCode);
      // this.$router.push({
      //   path: "/"+qrCode+"/"+projectCode,
      // });
      Toast({
        message: "登录状态超时，请重新扫码进入",
        forbidClick: true,
      });
     }else{
      return Promise.resolve(res);
    }
  },
  (error) => {
    console.log("err" + error); // for debug
    Toast({
      message: "服务器升级中，请稍后重试",
      forbidClick: true,
    });
    return Promise.reject(error);
  }
);
export default service;
