import axios from "axios";
console.log(process.env.VUE_APP_BASE_API);
const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API, // url = base url + request urlzyxjml
  // baseURL: "/refactor", // url = base url + request urlzyxjml
  withCredentials: true,
  crossDomain: true,
  timeout: 50000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers.token = localStorage.getItem("token");
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    // return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {},
  (error) => {
    // return Promise.reject(error);
  }
);
export default service;
