import request from "../utils/request";
import service from "../utils/journalRequest";
import Qs from "qs";
export function getTableData(params) {
  return request({
    url: "/history/getHistoryScanRecord",
    method: "post",
    data: params,
  });
}
export function getRedirectUrl(params) {
  console.log(params);
  return request({
    url: "/index/getRedirectUrl",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getLocationParams(params) {
  return request({
    url: "/index/locationParams",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getUserInfo(params) {
  return request({
    url: "/index/getInfo",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getImgUrl(params) {
  return request({
    url: "/luckyDraw/queryTurntableImagesAndSweepstakesRules",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getLocation(params) {
  return request({
    url: "/index/location",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getDrawRecord(params) {
  return request({
    url: "/luckyDraw/queryDrawRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function getWinningRecord(params) {
  return request({
    url: "/bonus/queryWinningRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function checkProjectConfig(params) {
  return request({
    url: "/projectCheck/checkProjectConfig",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function codeCheck(params) {
  return request({
    url: "/projectCheck/codeCheck",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function drawRecord(params) {
  return request({
    url: "/luckyDraw/drawRecord",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function receiveRedPacket(params) {
  return request({
    url: "/luckyDraw/receiveRedPacketByDouMi",
    method: "post",
    data: Qs.stringify(params),
  });
}
export function selectPrizeUrl(params) {
  return request({
    url: "/bonus/selectPrizeUrl",
    method: "post",
    data: Qs.stringify(params),
  });
}
// 埋点请求
export function journal(params) {
  params.fromType = "0";
  params.qrCode = sessionStorage.getItem("qrCode");
  params.projectCode = sessionStorage.getItem("projectCode");
  params.screenLength = document.documentElement.clientHeight;
  params.screenWidth = document.documentElement.clientWidth;
  return service({
    url: "/osa/logAgent/save",
    method: "post",
    data: Qs.stringify(params),
  });
}
