import wx from "weixin-jsapi";
import { getLocationParams } from "../api/index.js";
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

export function wxshareNO() {
  var code = sessionStorage.getItem("projectCode");
  getLocationParams({ projectCode: code }).then((res) => {
    var timestamp = res.data.timestamp;
    var appId = res.data.appId;
    var nonceStr = res.data.nonceStr;
    var signature = res.data.signature;
    wx.config({
      debug: false,
      appId: appId,
      timestamp: timestamp,
      nonceStr: nonceStr,
      signature: signature,
      jsApiList: ["hideOptionMenu"],
    });
    wx.ready(function () {
      wx.hideOptionMenu();
    });
  });
}
export function isFunction(val) {
  return typeof val == "function";
}
export function getSessionStorage(key) {
  let val = sessionStorage.getItem(key);
  try {
    return JSON.parse(val);
  } catch {
    return val;
  }
}