// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import request from "./utils/request";
import "amfe-flexible";
import "./styles/base.css";
import scroll from "vue-seamless-scroll";
import { Toast } from "vant";
import qs from "qs";
import { nextRequest } from "./utils/tracking";
Vue.prototype.$nextRequest = nextRequest;
Vue.use(Toast);
Vue.use(scroll);
Vue.config.productionTip = false;
Vue.prototype.$baseUrl = process.env.baseUrl;
Vue.prototype.$qs = qs;
Vue.prototype.req = request;
/* eslint-disable no-new */
// new Vue({
//   el: "#app",
//   router,
//   components: { App },
//   template: "<App/>",
// });

new Vue({
  el: "#app",
  // store,
  router,
  render: (h) => h(App),
}).$mount("#app");
